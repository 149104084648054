<template>
   <div class="loadpage">
		<van-nav-bar title="页面导航" />
		<div style="margin-top: 10px;">
			<router-link to="/login"><van-button plain type="primary">登录</van-button></router-link>
			<router-link to="/register"><van-button plain type="primary">注册</van-button></router-link>
			<router-link to="/information"><van-button plain type="info">完善信息</van-button></router-link>
			<router-link to="/empower"><van-button plain type="info">信息授权</van-button></router-link>
			<router-link to="/reportlist"><van-button plain type="info">四诊报告列表</van-button></router-link>
			<router-link to="/questionbrief"><van-button plain type="info">体质辨识</van-button></router-link>
			<router-link to="/scandevice"><van-button plain type="info">扫描设备</van-button></router-link>
			<router-link to="/scanresult"><van-button plain type="info">扫描设备结果</van-button></router-link>
			<router-link to="/scan"><van-button plain type="info">扫描问卷</van-button></router-link>
			<router-link to="/about"><van-button plain type="info">关于我们</van-button></router-link>
			<!-- <router-link to="/archives"><van-button plain type="info">学习档案</van-button></router-link> -->
			<!-- <router-link to="/analysislist"><van-button plain type="info">问卷报告列表</van-button></router-link> -->
	  </div>
   </div>
</template>
<script>

import { Button,NavBar } from 'vant';
export default {
   name: "home",
   data() {
		return {
      
		}
   },

	components: {
		[Button.name]: Button,
		[NavBar.name]: NavBar
	},

	methods: {
		
	},
	mounted() {
   
	}
};
</script>
<style lang="less" scope>
	.loadpage{
		.van-button{
			margin: 10px;
		}
	}

</style>